'use strict';

//const $ = require('jquery');


/**
 * Homepage JS
 */
class Home {

	constructor (){
		this.events();
		//this.$container = $('#main-content');
	}

	/**
	 * Event listeners
	 */
	events (){
		//this.$container.on('click', '.post', this.doSomethingHandler.bind(this));
	}

	/**
	 * Do something click handler method
	 */
	doSomethingHandler (){



	}



}

module.exports = Home;
