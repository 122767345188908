'use strict';
import jump from 'jump.js';

/**
 * ToTop JS
 */
class ToTop {

	constructor (){
		this.totop = document.querySelector('a.top');
		//this.scrollDown = document.querySelector('a.scroll-down');
		this.events();
	}

	/**
	 * Event listeners
	 */
	events (){
		this.totop.addEventListener('click', this.toTop.bind(this,this.totop));
		setInterval((function(){
			let windowPos = window.pageYOffset || window.scrollY;
			if (! this.totop.classList.contains('visible') && windowPos > 300) {
				this.totop.classList.add('visible');
			}
			if (windowPos <=300) {
				this.totop.classList.remove('visible');
			}
		}).bind(this), 1000);

		// also using the toTop function
		if ( this.scrollDown ) {
			this.scrollDown.addEventListener('click', this.toTop.bind(this, this.scrollDown));
		}
	}
	
	totopTimer () {
		if (! this.totop.classList.contains('visible') && window.scrollY > 300) {
			this.totop.classList.add('visible');
		}
		if (window.scrollY <=300) {
			this.totop.classList.remove('visible');
		}
	}
	
	
	toTop(el,e) {
		e.preventDefault();
		if (document.querySelector('.page-header')) {
			jump('.page-header',{duration:400, offset: -100});
		} else {
			jump('.page-header',{duration:400, offset: -100});
		}
	}
	

}

module.exports = ToTop;
