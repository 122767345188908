'use strict';

const Choices = require('../vendor/choices/choices.min');


/**
 * Contact form JS
 */
class Contact {

	constructor (){
		this.contactForm = document.querySelector('.contact-form');
		this.formItems = this.contactForm.querySelectorAll('.form-item');
		this.formPagers = this.contactForm.querySelectorAll('.form-pager');
		this.formSubmit = this.contactForm.querySelector('[type="submit"]');
		this.formInputs = this.contactForm.querySelectorAll('input');
		this.currentItem = 0;
		if (this.contactForm.querySelector('select')) {

			var choices = new Choices('.contact-form select',{
				searchEnabled: false,
				searchChoices: false,
			});
		}
		this.events();
	}

	/**
	 * Event listeners
	 */
	events (){
		for(var i = 0;i<this.formPagers.length;i++) {
			this.formPagers[i].addEventListener('click', this.formNavigate.bind(this, this.formPagers[i]));
		}
		this.contactForm.addEventListener('keyup', this.formKeyUp.bind(this, this.contactForm));
		this.contactForm.addEventListener('keydown', this.formKeyBlock.bind(this, this.contactForm));
		this.contactForm.addEventListener('keypress', this.formKeyBlock.bind(this, this.contactForm));
		this.formSubmit.addEventListener('click', this.formSubmitHandler.bind(this, this.formSubmit));
	}

	formKeyBlock(el, e) {
		if (e.keyCode == 13) {
			e.preventDefault();
			e.stopPropagation();
		}
	}


	formKeyUp(el, e) {
		if ((e.target.type == 'text' || e.target.type == 'email' || e.target.type == 'textarea') && e.keyCode == 13) {
			e.preventDefault();
			e.stopPropagation();
			this.formNavigate(e.target, e);
		} else if (e.target.type == 'submit' && e.keyCode == 13) {
			this.formSubmitHandler(el, e);
		}
	}


	formNavigate(el, e) {
		e.preventDefault();

		// is the current field required?
		var req = this.formItems[this.currentItem].querySelector('[required]');
		if (req && ! req.value) {
			this.formItems[this.currentItem].querySelector('.validation').classList.add('active');
			return;
		} else if (req && req.value) {
			this.formItems[this.currentItem].querySelector('.validation').classList.remove('active');
		}

		if (el.getAttribute('data-pager') == 'next') {
			this.currentItem++;
		} else if (el.getAttribute('data-pager') == 'prev') {
			this.currentItem--;
		}
		if (this.currentItem < 0) {
			this.currentItem = 0;
		} else if (this.currentItem >= (this.formItems.length - 1)) {
		}
		for(var i = 0; i<this.formItems.length; i++) {
			this.formItems[i].classList.remove('active');
			var msgPreview = 'From: ' + this.contactForm.querySelector('input[name="name"]').value + ' (' + this.contactForm.querySelector('input[name="email"]').value + ')' + '<br>';
			if (this.contactForm.querySelector('select[name="practice-area"]')) {
				msgPreview = msgPreview + 'Regarding: ' + this.contactForm.querySelector('select[name="practice-area"]').value + '<br>';
			}
			msgPreview = msgPreview + '<br>' + this.contactForm.querySelector('textarea').value;
			this.contactForm.querySelector('.preview').innerHTML = msgPreview;
		}
		if (this.currentItem == this.formItems.length - 1) {
			this.contactForm.querySelector('.pager').classList.add('active');
			this.contactForm.querySelector('.pager .next').classList.add('invisible');
		} else if (this.currentItem > 0) {
			this.contactForm.querySelector('.pager').classList.add('active');
			this.contactForm.querySelector('.pager .previous').classList.add('invisible');
			if (this.currentItem > 1) {
				this.contactForm.querySelector('.pager .previous').classList.remove('invisible');
			}
			this.contactForm.querySelector('.pager .next').classList.remove('invisible');

		} else {
			this.contactForm.querySelector('.pager').classList.remove('active');
		}


		this.formItems[this.currentItem].classList.add('active');
		this.formItems[this.currentItem].querySelector('input,textarea,select').focus();
	}

	formSubmitHandler(el, e) {
		e.preventDefault();
		var url = this.contactForm.getAttribute('action');
		var formData = '';
		var inputs = this.contactForm.querySelectorAll('input,textarea,select');
		for(var i = 0;i < inputs.length; i++) {
			formData += (formData == '' ? '':'&') + inputs[i].getAttribute('name')+'='+inputs[i].value;
		}

		var xhr = new XMLHttpRequest();
		xhr.onload = e => {
			if (xhr.status == 200) {
  			console.log(xhr);
				this.formSubmitResponse(JSON.parse(xhr.responseText));
			} else {
  			console.log(xhr);
			}
		}
		xhr.open('POST', url, true)
		xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
		xhr.send(formData);
	}

	formSubmitResponse(data) {
		if (data.status) {
			this.contactForm.innerHTML = '<p class="success">' + data.message + '</p>';
		} else {
  		console.log(data);
		}
	}



}

module.exports = Contact;
