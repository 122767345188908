'use strict';


/**
 * Viewport JS
 */
class Viewport {

	constructor (){
		
		this.viewport_elements = document.querySelectorAll('.viewport');
		this.viewport_scrollable = document.querySelectorAll('.scrollable');
		if (this.viewport_elements.length || this.viewport_scrollable.length) {
			this.events();
		}
	}

	/**
	 * Event listeners
	 */
	events (){
		if (this.viewport_elements.length) {
			//window.addEventListener('scroll', this.windowScroll.bind(this, this.viewport_elements));
			setInterval(this.windowScroll.bind(this), 20);
		}
		if (this.viewport_scrollable.length) {
			this.intervalID = setInterval(this.windowScrollable.bind(this),20);
		}
	}
	
	windowScroll() {
		for (var i = 0; i < this.viewport_elements.length; i++) {
			if (this.isInView(this.viewport_elements[i])) {
				this.viewport_elements[i].classList.add('viewable');
			} else {
				this.viewport_elements[i].classList.remove('viewable');
			}
		}
	}
	
	windowScrollable() {
		for (var i = 0; i < this.viewport_scrollable.length; i++) {
			var p = this.percentInView(this.viewport_scrollable[i]) / 100;
			var scrollable_elems = this.viewport_scrollable[i].querySelectorAll('[data-animate]');
			for (var s = 0; s < scrollable_elems.length; s++) {
				var properties = JSON.parse(scrollable_elems[s].dataset.animate);
				for (var key in properties.start) {
					var units = properties.start[key].toString();
					var startFloat = parseFloat(properties.start[key]).toString();
					units = units.replace(startFloat,'');
					if (parseFloat(properties.start[key]) == 0 && parseFloat(properties.end[key]) == 1) {
						var delta = p;
					} else {
						var delta = (parseFloat(properties.end[key]) + parseFloat(properties.start[key]) - (p * parseFloat(properties.start[key])));
					}
					if (units.length) {
						scrollable_elems[s].style[key] = delta+units;
					} else {
						scrollable_elems[s].style[key] = delta;
					}
				}
			}
		}
	}
	
	isInView(elm) {
		var rect = elm.getBoundingClientRect();
		var html = document.documentElement;
		var windowBottom = (window.innerHeight || html.clientHeight);
		if (rect.top <= windowBottom) {
			return true;
		}
		return (
		    rect.top >= 0 &&
		    rect.left >= 0 &&
		    rect.bottom <= (window.innerHeight || html.clientHeight) &&
		    rect.right <= (window.innerWidth || html.clientWidth)
		);
	}
	
	percentInView(elm) {
		var rect = elm.getBoundingClientRect();
		var html = document.documentElement;
		var windowBottom = (window.innerHeight || html.clientHeight);
		var elm_height = (rect.bottom - rect.top) / 2;
		if (rect.top < windowBottom) {
			var p = 100 - ((rect.top + elm_height) - (windowBottom / 2)) * 100 / (windowBottom / 2);
			if (p > 100) {
				p = 100 - (p - 100);
				p = 100;
			}
			return p;
		} else {
			return 0;
		}
	}
}

module.exports = Viewport;
