'use strict';

//const $ = require('jquery');
import * as moment from 'moment';
import 'core-js/es6/object';
const Choices = require('../vendor/choices/choices.min-4.1.0');
		
var intervalId, formTop;


/**
 * Filter JS
 */
class Filter {

	constructor (){
		this.filterForm = document.querySelector('form.filters');
		
		this.filters = document.querySelectorAll('[data-filter]');
		if (this.filters && this.filters.length) {
			for (var f = 0; f < this.filters.length; f++) {
				this.filters[f]._options = this.filters[f].querySelectorAll('option');
			}
		}
		this.hashLinks = document.querySelectorAll('#primary-nav a');
		this.loadMore = document.querySelector('.load-more');
		this.results = document.querySelector('.results-section');
		this.row = document.querySelector('form.filters').getAttribute('data-per-row');
		this.moreLinks = document.querySelectorAll('.more[data-cat]');
		this.originalResults = this.results.innerHTML;
		if (document.querySelector('.name-search')) {
			this.fakeSubmit = document.querySelector('.name-search i.fa-search');
			this.fakeSubmit.addEventListener('click', this.fakeSubmitForm.bind(this, this.fakeSubmit));
		}
		this.queryOptions = {
			current_page: 0,
			offset: 0,
			per_page: 12,
			total_pages: 0,
			new_posts: 'add',
			init_post: false,
		}
		formTop = this.filterForm.getBoundingClientRect().top - 100;
		
		this.pager = document.querySelector('.posts-pager');
		
		if (window.location.hash) {
			var hash_vars = window.location.hash.split(/#|%23/);
			for (var f = 0; f < this.filters.length; f++) {
				for (var i = 0; i < this.filters[f]._options.length; i++) {
					for (var h = 0; h < hash_vars.length; h++) {
						if ((this.filters[f]._options[i].value == hash_vars[h]) || (this.filters[f]._options[i].value.toLowerCase() == hash_vars[h].toLowerCase()) || (this.filters[f]._options[i].text.toLowerCase() == hash_vars[h].toLowerCase()) || (this.filters[f]._options[i].value == 'publications' && hash_vars[h] == 'articles')) {
							this.queryOptions.init_post = true;
							this.filters[f].selectedIndex = i;
						}
					}
				}
			}
		}
		for (var f = 0; f < this.filters.length; f++) {
			if (this.filters[f].selectedIndex >= 0) {
				this.queryOptions[this.filters[f].getAttribute('data-filter')] = this.filters[f].querySelectorAll('option')[this.filters[f].selectedIndex].value;
			} else {
				this.queryOptions[this.filters[f].getAttribute('data-filter')] = '';
			}
		}
		
		this.events();
		
		if (this.queryOptions.type == 'people') {
			this.queryOptions.per_page = 100;
			this.filterChange();
		} else if (this.queryOptions.init_post) {
			this.postQuery();
		}
		for (var i = 0; i < this.filters.length; i++) {
			if (this.filters[i].nodeName == 'SELECT') {
				this.filters[i].opts = {};
				var options = this.filters[i].querySelectorAll('option');
				for (var o = 0; o < options.length; o++) {
					this.filters[i].opts[options[o].value] = options[o].label;
				}
				if (Object.assign == undefined) {
				} else {
					this.filters[i].choice = new Choices(this.filters[i], {//'select[data-filter]',{
						placeholder: true,
						placeholderValue: 'Options',
						searchEnabled: false,
						searchChoices: false,
						itemSelectText:'',
						shouldSort: false,
					});
				}
			}
		}
		/*
		this.choices.push(new Choices('select[data-filter]',{
			placeholder: true,
			placeholderValue: 'Options',
			searchEnabled: false,
			searchChoices: false,
		}));*/
	}

	/**
	 * Event listeners
	 */
	events (){
		
		for(var i=0;i<this.filters.length;i++) {
			this.filters[i].addEventListener('change', this.filterChange.bind(this, this.filters[i]));
			if (this.filters[i].hasAttribute('data-default-value')) {
				var filterName = this.filters[i].getAttribute('data-filter').replace('data-','');
				var defaultFilterValue = this.filters[i].getAttribute('data-default-value');
				this.queryOptions[filterName] = defaultFilterValue;
			}
		}
		this.pager.addEventListener('click', this.setPage.bind(this, this.pager));
		this.loadMore.addEventListener('click', this.loadMorePosts.bind(this, this.loadMore));
		
		this.filterForm.addEventListener('keyup', this.formKeyBlock.bind(this, this.filterForm));
		this.filterForm.addEventListener('keydown', this.formKeyBlock.bind(this, this.filterForm));
		this.filterForm.addEventListener('keypress', this.formKeyBlock.bind(this, this.filterForm));
		this.filterForm.addEventListener('click', this.filterFormClicks.bind(this, this.filterForm));
		if (this.moreLinks.length) {
			for (var i = 0; i < this.moreLinks.length; i++) {
				this.moreLinks[i].addEventListener('click', this.getMoreByType.bind(this, this.moreLinks[i]));
			}
		}
		if (this.hashLinks.length) {
			for (var i = 0; i < this.hashLinks.length; i++) {
				if (this.hashLinks[i].getAttribute('href').indexOf('#')) {
					this.hashLinks[i].addEventListener('click', this.hashLink.bind(this, this.hashLinks[i]));
				}
			}
		}
		
	}
	
	hashLink(el, e) {
		//e.preventDefault();
		e.stopPropagation();
		var _hash = e.target.href.replace('#','');
		_hash = _hash.split('/');
		_hash = _hash[_hash.length - 1];
		
		for (var f = 0; f < this.filters.length; f++) {
			for (var i = 0; i < this.filters[f]._options.length; i++) {
				if (this.filters[f]._options[i].text.toLowerCase() == _hash.toLowerCase()) {
					this.filters[f].choice.setValue([_hash]);
					var filterName = this.filters[f].getAttribute('data-filter').replace('data-','');
					this.queryOptions[filterName] = this.filters[f]._options[i].value;
					this.postQuery();
				}
			}
		}
	}
	
	
	
	formKeyBlock(el, e) {
		if (e.keyCode == 13) {
			e.preventDefault();
			e.stopPropagation();
			document.querySelector('input[name="name"]').blur();
		}
	}
	
	parseDate(date){
	    return moment.default(date).format('ll');
	}
	
	fakeSubmitForm(el,e) {
		console.log('fakeSubmitForm', e);
	}
	
	setPage(el,e) {
		e.preventDefault();
		if (e.srcElement.dataset.page) {
			this.queryOptions.current_page = e.srcElement.dataset.page;
			this.queryOptions.offset = this.queryOptions.current_page * this.queryOptions.per_page;
			this.queryOptions.new_posts = 'replace';
			this.postQuery();
		}
	}
	
	filterFormClicks(el, e) {
		if (e.target.hasAttribute('data-cat')) {
			this.filterForm.querySelector('[name="category_name"]').choice.setChoiceByValue(e.target.getAttribute('data-cat'));
			this.queryOptions.category_name = e.target.getAttribute('data-cat');
			this.postQuery();
		}
	}
	
	getMoreByType(el, e) {
		e.preventDefault();
		this.filterForm.querySelector('[name="category_name"]').choice.setChoiceByValue(el.getAttribute('data-cat'));
		this.queryOptions.category_name = el.getAttribute('data-cat');
		this.postQuery();
		
	}

	
	filterChange(el,e) {
		if (e) {
			e.preventDefault();
		}
		this.queryOptions.current_page = 0;
		this.queryOptions.offset = 0;
		this.queryOptions.total_pages = 0;
		for (var i = 0; i < this.filters.length; i++) {
			var filterName = this.filters[i].getAttribute('data-filter').replace('data-','');
			var filterValue = this.filters[i].value.replace('&','__');
			if (filterValue == 'all') filterValue = '';
			this.queryOptions[filterName] = filterValue;
		}
		this.postQuery();
	}
	
	postQuery() {
		var q = '';
		for (var key in this.queryOptions) {
			if (this.queryOptions[key] != 'all') {
				if (this.queryOptions.type == 'post') {
					q += (q.length ? '&' : '?') + key + '=' + this.queryOptions[key];
				} else {
					if (this.queryOptions.name == '') {
						q += (q.length ? '&' : '?') + key + '=' + this.queryOptions[key];
					} else {
						if (key == 'title' || key == 'areas_of_practice' || key == 'address_office') {
						} else {
							q += (q.length ? '&' : '?') + key + '=' + this.queryOptions[key];
						}
					}
				}
			}
		}
		document.body.classList.add('posting');
		this.filterForm.classList.add('posting');
		
		var xhr = new XMLHttpRequest();
		xhr.onload = e => {
			if (xhr.status == 200) {
				this.queryOptions.total_pages = xhr.getResponseHeader('X-WP-TotalPages');
				this.queryOptions.total_posts = xhr.getResponseHeader('X-WP-Total');
				this.postQueryResponse(JSON.parse(xhr.responseText));
				
				this.filterForm.classList.remove('posting');
				document.body.classList.remove('posting');
			}
		}
		//console.log('/wp-json/api/posts'+q);
		
		
		xhr.open('GET', '/wp-json/api/posts'+q, true)
		xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
		xhr.send();
	}
	
	
	postQueryResponse(data) {
		var _output = '';
		if (data.length) {
			for(var i=0;i<data.length;i++) {
				if (this.queryOptions.type == 'post') {
					_output += this.themeArticleTeaser(data[i]);
				} else if (this.queryOptions.type == 'people') {
					_output += this.themePeopleTeaser(data[i]);
				}
			}
			
			if (this.queryOptions.type == 'post') {
				if (this.queryOptions.category_name == '' && this.queryOptions.areas_of_practice == '') {
					// Basic reset:
					_output = this.originalResults;
				} else if (this.queryOptions.category_name) {
					_output = '<h4 class="category">' + this.filters[1].opts[this.queryOptions.category_name] + '</h4><div class="results">' + _output + '</div>';
					//document.querySelector('.category-title').innerHTML = '<h4 class="category">' + this.filters[1].opts[this.queryOptions.category_name] + '</h4>';
				} else {
					_output = '<h4 class="category">' + this.filters[2].opts[this.queryOptions.areas_of_practice] + '</h4><div class="results">' + _output + '</div>';
				}
			}

			if (this.queryOptions.type == 'people' && (i % 4 > 0)) {
				for (var placeholder = 0; placeholder < (4 - (i % 4)); placeholder++) {
					_output += '<article class="teaser people placeholder"></article>';
				}
			}
			if (this.queryOptions.category_name) {
				this.results.classList.add('single-category');
				this.results.setAttribute('rel', this.queryOptions.category_name);
			} else {
				this.results.classList.remove('single-category');
				this.results.removeAttribute('rel');
			}
			
		} else {
			_output = '<p class="no-results">Sorry, nothing found.</p>';
		}
		this.pager.innerHTML = '';
		if (this.queryOptions.total_pages > 1) {
			if (this.queryOptions.current_page > 5) {
				var a = document.createElement('a');
				a.setAttribute('data-page',0);
				a.appendChild(document.createTextNode('first…'));
				this.pager.appendChild(a);
			}
			for(var p = 0; p < this.queryOptions.total_pages; p++) {
				if (p > parseInt(this.queryOptions.current_page) - 5 && p < parseInt(this.queryOptions.current_page) + 5) {
					var a = document.createElement('a');
					if (p == this.queryOptions.current_page) {
						a.classList.add('active');
					}
					a.setAttribute('data-page',p);
					a.appendChild(document.createTextNode(p+1));
					this.pager.appendChild(a);
				}
			}
			if (this.queryOptions.current_page < (this.queryOptions.total_pages - 6)) {
				var a = document.createElement('a');
				a.setAttribute('data-page',(this.queryOptions.total_pages - 1));
				a.appendChild(document.createTextNode('…last'));
				this.pager.appendChild(a);
			}
		}
		if (data.length < this.queryOptions.per_page) {
			this.loadMore.classList.remove('active');
		}
		if (this.queryOptions.current_page == 0 || this.queryOptions.new_posts == 'replace') {
			this.results.innerHTML = _output;
		} else {
			this.results.innerHTML += _output;
		}
		if (this.queryOptions.type != 'people') {
			this.intervalId = setInterval(this.scrollStep.bind(this), 16);
		}
	}
	
	scrollStep() {
	    // Check if we're at the top already. If so, stop scrolling by clearing the interval
	    if (window.pageYOffset <= formTop) {
	        clearInterval(this.intervalId);
	        this.intervalId = 0;
	    } else if (this.intervalId > 0) {
		    window.scroll(0, window.pageYOffset - 50);
	    }
	}
	
	themeArticleTeaser(article) {
		var _output = `<a class="teaser post arrow-right" href="${article.link}">`;
		let date = this.parseDate(article.date);
		if (article.better_featured_image) {
			if (article.better_featured_image.media_details.sizes.post_teaser_wide) {
				_output += `<img src="${article.better_featured_image.media_details.sizes.post_teaser_wide.source_url}">`;
			}
		} else if (article.acf.area) {
			_output += `<img src="/app/themes/singleton/assets/img/thumbnails/${article.acf.area}.jpg">`;
		} else {
			_output += `<img src="/app/themes/singleton/assets/img/thumbnails/generic.png">`;
		}
			
		_output += `<p class="date">`;
		if (article.cats && article.cats[0]){
			_output += `<span class="category">${article.cats[0]}</span><span class="separator"> | </span>`;
		}
		_output += ` ${date}</p><h3>${article.title.rendered}</h3>`;
		_output += `<span class="arrow"></span></a>`;
		return _output;
	}
	
	themePeopleTeaser(person) {
		var _output = `<article class="teaser people">`;
		if (person.better_featured_image) _output += `<a href="${person.link}" class="img"><img src="${person.better_featured_image.media_details.sizes.square300.source_url}"></a>`;
		var _title;
		if (person.acf.title) _title = person.acf.title;
		if (person.acf.student) _title = person.acf.student;
		if (person.acf.lawyer) _title = person.acf.lawyer.join(', ');
		if (person.acf.title_other) _title = person.acf.title_other;
		
		if (_title == 'Paralegal' && person.acf.address.office == 'Toronto') _title = 'Law Clerk';
		
		_output += `<h3><a href="${person.link}">${person.title.rendered}` + (person.acf.titlesinitials ? ` <span>${person.acf.titlesinitials}</span>` : '') + `</a></h3><h4>${_title}</h4><p>${person.acf.telephone}<br><a href="mailto:${person.acf.email_address}">${person.acf.email_address}</a></p></article>`;
		return _output;
	}
		
	
	loadMorePosts(el,e) {
		this.queryOptions.current_page++;
		this.queryOptions.offset = this.queryOptions.current_page * this.queryOptions.per_page;
		this.postQuery();
	}

}

module.exports = Filter;
