'use strict';


/**
 * Sticky JS
 */
class Sticky {

	constructor (){
		
		this.sticky_elements = document.querySelectorAll('[data-sticky-at]');
		if (this.sticky_elements.length || this.sticky_elements.length) {
			this.events();
		}
		this.debugCounter = 0;
	}

	/**
	 * Event listeners
	 */
	events (){
		window.addEventListener('scroll', this.windowScroll.bind(this, this.sticky_elements));
	}
	
	windowScroll(elms) {
		//this.debugCounter++;
		//console.log('sticky.js windowScroll ' + this.debugCounter);
		for (var i = 0; i < elms.length; i++) {
			var sticky_media_query = true;
			if (elms[i].hasAttribute('data-sticky-query')) {
				var mq = elms[i].getAttribute('data-sticky-query');
				if (mq.indexOf('<') >=0 && parseInt(mq) < window.innerWidth) {
					sticky_media_query = false;
				}
				if (mq.indexOf('>') >=0 && parseInt(mq) > window.innerWidth) {
					sticky_media_query = false;
				}
			}
			if (sticky_media_query) {
				var rect = elms[i].getBoundingClientRect();
				var sticky_target = elms[i].getAttribute('data-sticky-at');
				if (isNaN(sticky_target)) {
					var targetElementRect = document.querySelector(sticky_target).getBoundingClientRect();
					sticky_target = targetElementRect.bottom;
				}
				if (rect.top <= sticky_target) {
					if (! elms[i].classList.contains('fixed')) {
						elms[i].classList.add('fixed');
						elms[i].style.display = 'none';
						elms[i].style.display = 'block';
						for (var c = 0; c < elms[i].childNodes.length; c++) {
							if (elms[i].childNodes[c].nodeType == 1) {
								elms[i].childNodes[c].style.top = sticky_target + 'px';
								break;
							}
						}
					}
				} else {
					if (elms[i].classList.contains('fixed')) {
						elms[i].classList.remove('fixed');
						for (var c = 0; c < elms[i].childNodes.length; c++) {
							if (elms[i].childNodes[c].nodeType == 1) {
								elms[i].childNodes[c].style.top = 'auto';
								break;
							}
						}
					}
				}
			}
		}
	}
}

module.exports = Sticky;
