'use strict';

//const $ = require('jquery');


/**
 * Slideshow JS
 */
class Slideshow {

	constructor (){
		this.slideshow = document.querySelector('.slideshow');
		this.slideshow.slides = this.slideshow.querySelectorAll('.slide');
		this.slideshow.currentSlide = 0;
		this.slideshow.timeoutActive = 1;
		this.slideshow.timeoutTime = 0;
		this.initTime = 0;
		this.slideshow.interval = parseInt(this.slideshow.getAttribute('data-interval') || 8000);
		this.previous = this.slideshow.querySelector('.previous');
		this.next = this.slideshow.querySelector('.next');
		// Add a sentry:
		if (this.slideshow.classList.contains('sentry')) {
			var maxSlideHeight = 0;
    		var tallestSlide = -1;
    		for (var i = 0; i < this.slideshow.slides.length; i++) {
	      		if (this.slideshow.slides[i].getBoundingClientRect().height > maxSlideHeight) {
	        		maxSlideHeight = this.slideshow.slides[i].getBoundingClientRect().height;
	        		tallestSlide = i;
	      		}
	    		this.slideshow.slides[i].classList.add('positioned');
    		}
    		if (tallestSlide >= 0) {
	      		var sentrySlide = this.slideshow.slides[tallestSlide].cloneNode(true);
	      		sentrySlide.classList.add('sentry');
	      		sentrySlide.classList.remove('positioned');
	      		if (this.slideshow.slides.length > 1) {
		      		sentrySlide.classList.add('with-pager');
	      		}
	      		this.slideshow.appendChild(sentrySlide);
    		}

		}


		// build a pager?
		var pager = document.createElement('ul');
		pager.classList.add('pager');
		for (var i = 0; i < this.slideshow.slides.length; i++) {
			var li = document.createElement('li');
			li.setAttribute('data-slide', i);
			if (i == 0) {
				this.slideshow.slides[0].classList.add('active');
				li.classList.add('active');
			}
			li.addEventListener('click', this.slideshowPager.bind(this, li));
			pager.appendChild(li);
		}
		const pagerWrap = this.slideshow.querySelector('.pager-wrap .wrap');
		if (pagerWrap) {
			pagerWrap.appendChild(pager);
		}
		this.slideshow.pager = this.slideshow.querySelectorAll('.pager li');
		this.slideshow.touchStart = 0;
		this.slideshow.touchEnd = 0;
		this.slideshow.addEventListener('touchstart', this.slideshowTouchStart.bind(this, this.slideshow));
		this.slideshow.addEventListener('touchend', this.slideshowTouchEnd.bind(this, this.slideshow));

		this.events();
		//this.slideshowTimer();

	}

	/**
	 * Event listeners
	 */
	events (){

		var d = new Date;
		this.initTime = d.getTime();
		if (this.previous) {
			this.previous.addEventListener('click', this.slideshowPrevious.bind(this, this.previous));
		}
		if (this.next) {
			this.next.addEventListener('click', this.slideshowNext.bind(this, this.next));
		}
		if (this.slideshow.slides.length > 1) {
  		this.slideshow.timeoutTime = d.getTime();
			setTimeout((function() {
				this.timer = setInterval((this.slideshowTimer).bind(this), this.slideshow.interval)
			}).bind(this),1000);
		}
	}

	slideshowTimer() {
		var d = new Date;
		//console.log(d.getTime(), (this.slideshow.timeoutTime + this.slideshow.interval), (d.getTime() - (this.slideshow.timeoutTime + this.slideshow.interval)) );
		if (this.slideshow.timeoutActive && (d.getTime() >= (this.slideshow.timeoutTime + this.slideshow.interval))) {
  		this.slideshow.timeoutTime = d.getTime();
			this.slideshow.currentSlide++;
			if ( this.slideshow.currentSlide >= this.slideshow.slides.length) {
				this.slideshow.currentSlide = 0;
			}
			this.slideshowGoto(this.slideshow.currentSlide);
		}
	}

	slideshowTouchStart(el,e) {
  	this.slideshow.touchStart = e.changedTouches[0].clientX;
	}
	slideshowTouchEnd(el,e) {
  	this.slideshow.touchEnd = e.changedTouches[0].clientX;
    if (this.slideshow.touchEnd < (this.slideshow.touchStart - 100)) {
      this.slideshowNext(el,e);
    } else if (this.slideshow.touchEnd > (this.slideshow.touchStart + 100)){
      this.slideshowPrevious(el, e);
    }
	}
	slideshowPrevious(el, e) {
		this.slideshow.currentSlide--;
		if ( this.slideshow.currentSlide < 0) {
			this.slideshow.currentSlide = this.slideshow.slides.length - 1;
		}
		this.slideshowGoto(this.slideshow.currentSlide);
		clearInterval(this.timer);
		this.timer = setInterval((this.slideshowTimer).bind(this), this.slideshow.interval);
	}
	slideshowNext(el, e) {
		this.slideshow.currentSlide++;
		if ( this.slideshow.currentSlide >= this.slideshow.slides.length) {
			this.slideshow.currentSlide = 0;
		}
		this.slideshowGoto(this.slideshow.currentSlide);
		clearInterval(this.timer);
		this.timer = setInterval((this.slideshowTimer).bind(this), this.slideshow.interval);
	}

	slideshowPager(el, e) {
		var p = el.getAttribute('data-slide');
		var d = new Date;

		this.slideshow.timeoutTime = d.getTime();
		this.slideshowGoto(p);
	}

	slideshowGoto(s) {
		this.slideshow.currentSlide = s;
		for (var i = 0;i<this.slideshow.slides.length; i++) {
			this.slideshow.slides[i].classList.remove('active');
			this.slideshow.pager[i].classList.remove('active');
		}
		this.slideshow.slides[s].classList.add('active');
		this.slideshow.pager[s].classList.add('active');
	}



}

module.exports = Slideshow;
