'use strict';

/**
 * Popup
 */
class Popup {

	constructor (){
		if (window.localStorage && document.body.classList.contains('front') && document.querySelector('.popup')) {
			if (window.localStorage['popupDisplayed'] && window.localStorage['popupDisplayed'] >= 1 ) {
			} else {
				window.localStorage['popupDisplayed'] = Math.floor(new Date().getTime() / 1000);
				document.querySelector('.popup').classList.add('active');
				setTimeout(function() {
					document.querySelector('.popup').classList.add('remove');
				}, 5000);
			}			
		}
	}

}

module.exports = Popup;
