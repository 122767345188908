'use strict';

//const $ = require('jquery');


/**
 * Search JS
 */
class Search {

	constructor (){
		this.searchToggle = document.querySelectorAll('.search-toggle');
		this.searchBox = document.querySelector('section.search');
		this.searchField = this.searchBox.querySelector('input[name="s"]');
		this.searchSuggestions = this.searchBox.querySelector('.search-suggestions');
		this.searchSuggestionsResults = this.searchSuggestions.querySelector('div');
		this.searchMicrotime = 0;
		this.searchPostTime = 0;
		this.events();
	}

	/**
	 * Event listeners
	 */
	events (){
		for(var i=0;i<this.searchToggle.length;i++) {
			this.searchToggle[i].addEventListener('click', this.searchBoxToggle.bind(this, this.searchToggle[i]));
		}
		this.searchField.addEventListener('keyup', this.searchFieldKeyup.bind(this, this.searchField));
	}
	
	searchBoxToggle(el,e) {
		e.preventDefault();
		this.searchBox.classList.toggle('active');
		if (this.searchBox.classList.contains('active')) {
			this.searchField.focus();
		}
	}
	
	searchFieldKeyup(el,e) {
		if (this.searchField.value.length > 3) {
			var d = new Date();
			var searchTime = d.getTime();
			if (searchTime > (this.searchMicrotime + 500)) {
				
				var xhr = new XMLHttpRequest();
				this.searchPostTime = new Date().getTime();
				xhr.onload = e => {
					console.log('Return search for ' + xhr.getResponseHeader('X-WP-Search'));
					if (xhr.status == 200 && xhr.getResponseHeader('X-WP-Time') == this.searchPostTime) {
						console.log('Display results for ' + xhr.getResponseHeader('X-WP-Search'));
						console.log(xhr.getResponseHeader('X-WP-Args'));
						this.searchResponse(JSON.parse(xhr.responseText));
					}
				}
				xhr.open('GET', '/wp-json/api/search?search='+this.searchField.value+'&t='+this.searchPostTime, true)
				xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
				xhr.send();
				this.searchMicrotime = searchTime;
			}
		}
	}
	
	
	searchResponse(data) {
		if (data.length) {
			this.searchSuggestions.classList.add('active');
			
			var resultsByType = {
				posts: [],
				people: [],
				areas: [],
				other: []
			};
			for (var i=0; i<data.length; i++) {
				
				if (data[i].type == 'post') {
					resultsByType.posts.push(data[i]);
				} else if (data[i].type == 'people') {
					resultsByType.people.push(data[i]);
				} else if (data[i].type == 'areas') {
					resultsByType.areas.push(data[i]);
				} else {
					resultsByType.other.push(data[i]);
				}
			}
			var resultTypeCount = 0;
			
			if (resultsByType.areas.length) resultTypeCount++;
			if (resultsByType.people.length) resultTypeCount++;
			if (resultsByType.posts.length) resultTypeCount++;
			if (resultsByType.other.length) resultTypeCount++;
			
			var _output = '';
			if (resultsByType.areas.length) {
				_output += '<div class="areas col-' + resultTypeCount + '"><h4>Practice Areas</h4><ul>';
				for (var i = 0; i<resultsByType.areas.length;i++) {
					_output += `<li><a href="${resultsByType.areas[i].link}">${resultsByType.areas[i].title.rendered}</a></li>`;
				}
				_output += '</ul></div>';
			}
			if (resultsByType.people.length) {
				_output += '<div class="people col-' + resultTypeCount + '"><h4>People</h4><ul>';
				for (var i = 0; i<resultsByType.people.length;i++) {
					_output += `<li><a href="${resultsByType.people[i].link}">`;
					if (resultsByType.people[i].better_featured_image && resultsByType.people[i].better_featured_image.media_details.sizes.thumbnail.source_url) _output += `<img class="tiny" src="${resultsByType.people[i].better_featured_image.media_details.sizes.thumbnail.source_url}">`;
					_output += `${resultsByType.people[i].title.rendered}</a></li>`;
				}
				_output += '</ul></div>';
			}
			if (resultsByType.posts.length) {
				_output += '<div class="posts col-' + resultTypeCount + '"><h4>News + Insights</h4><ul>';
				for (var i = 0; i<resultsByType.posts.length;i++) {
					if (i < 10) {
						_output += `<li><a href="${resultsByType.posts[i].link}">${resultsByType.posts[i].title.rendered}</a></li>`;
					}
				}
				if (i < 10) {
					_output += '</ul></div>';
				} else {
					_output += '</ul><a href="/?s=' + this.searchField.value + '&posts=post" class="button">See all</a></div>';
				}
			}
			if (resultsByType.other.length) {
				_output += '<div class="other col-' + resultTypeCount + '"><h4>Other</h4><ul>';
				for (var i = 0; i<resultsByType.other.length;i++) {
					if (i < 10) {
						_output += `<li><a href="${resultsByType.other[i].link}">${resultsByType.other[i].title.rendered}</a></li>`;
					}
				}
				_output += '</ul></div>';
			}
			this.searchSuggestionsResults.innerHTML = _output;
		} else {
			this.searchSuggestions.classList.remove('active');
		}
	}

}

module.exports = Search;
