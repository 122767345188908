'use strict';

// import 'babel-polyfill';
import jump from 'jump.js';
import * as moment from 'moment';
import NProgress from 'nprogress';
import Swiper from 'swiper';

//const $ = require('jquery');

const Home = require('./modules/home');
const Search = require('./modules/search');
const Filter = require('./modules/filters');
const Contact = require('./modules/contact');
const Slideshow = require('./modules/slideshow');
const Viewport = require('./modules/viewport');
const Sticky = require('./modules/sticky');
const ToTop = require('./modules/totop');
const Lightbox = require('./modules/lightbox');
const Popup = require('./modules/popup');


class App {

	constructor (){
		// just some console fun.
		console.log('%cBuilt by', 'font: 200 16px Calibri;color:#CCC');
		console.log('%cSignals', 'font: 200 28px Calibri;color:#93cb3c');
		console.log('%chttps://www.signals.ca', 'font: 200 16px Calibri;color:#CCC');


		this.search = new Search();
		this.body = document.querySelector('body');
		this.wrapperOverlay = document.querySelector('.wrapper__overlay');
		this.wrapperSpinner = document.querySelector('.wrapper__spinner');
		if (document.getElementById('contact_load_time')) {
  		var d = Date.now() / 1000;
  		document.getElementById('contact_load_time').value = d;
  		console.log(d);
		}

		if (document.querySelectorAll('[data-filter]').length) {
			this.filters = new Filter();
		}
		if (document.querySelector('ul.tab-titles')) {
			this.tabTitles = document.querySelector('ul.tab-titles');
			this.tabs = document.querySelectorAll('section.tab');
			this.inlineLinks = document.querySelectorAll('section.tab a');
		}
		if (document.querySelector('.contact-form')) {
			this.contact = new Contact();
		}
		if (document.querySelector('.slideshow')) {
			this.slideshow = new Slideshow();
		}
		if (document.querySelector('a.top')) {
			this.totop = new ToTop();
		}
		this.viewport = new Viewport();

		this.sticky = new Sticky();

		this.lightbox = new Lightbox();
		this.Popup = new Popup();

		this.navburger = document.querySelector('.navburger');
		this.mailchimp = document.querySelectorAll('.mailchimp');
		//this.submenuTriggers = document.querySelectorAll('.submenu-toggle');
		this.menu = document.getElementById('primary-nav');
		this.jumpers = document.querySelectorAll('.jump');

		if (window.location.hash) {
			document.body.classList.add('hashed');
		}

		this.accordions = document.querySelectorAll('[data-accordion]');

		this.toc = document.querySelectorAll('[data-toc]');

		if (document.querySelector('.swiper-container')) {
  		if (document.querySelectorAll('.ranking.swiper-slide').length > 3) {
    		if (window.innerWidth < 600) {
          var spv = 3;
          var sb = 0;
        } else {
          var spv = 5;
          var sb = 10;
        }
  			var mySwiper = new Swiper ('.swiper-container', {
  				autoplay: {
  					delay: 2000,
  				},
  				loop: true,
  				slidesPerView: spv,
  				spaceBetween: sb,
  				navigation: {
  					nextEl: '.swiper-next',
  					prevEl: '.swiper-prev',
  				}
  			});
			}
		}


		this.events();
		this.routes();


	}

	events () {

		document.addEventListener('DOMContentLoaded', () => {
			this.wrapperOverlay.classList.add('active');
			NProgress.configure({ showSpinner: false });
			// NProgress.start();
		});

		window.addEventListener('load', () => {
			this.body.classList.add('loaded');
			this.wrapperOverlay.classList.remove('active');
			this.wrapperOverlay.classList.add('inactive');
			// NProgress.done();
		});

		this.navburger.addEventListener('click', this.navburgerToggle.bind(this, this.navburger));
		if (this.tabTitles) {
			this.tabTitles.addEventListener('click', this.tabsToggle.bind(this, this.tabTitles));
		}
		for (var i = 0; i < this.mailchimp.length; i++) {
			this.mailchimp[i].addEventListener('click', this.mailchimpOpen.bind(this,this.mailchimp[i]));
		}
		this.menu.addEventListener('click', this.menuActions.bind(this, this.menu));

		if (document.querySelector('.area-images')) {
			setTimeout(function() {
				document.querySelector('.area-images').classList.add('visible');
			}, 1000);
		}

		if (this.jumpers.length) {
			for (var i = 0; i < this.jumpers.length; i++) {
				this.jumpers[i].addEventListener('click', this.jumpLink.bind(this, this.jumpers[i]));
			}
		}
		for (var i = 0; i < this.accordions.length; i++) {
			this.accordions[i].addEventListener('click', this.toggleAccordion.bind(this, this.accordions[i]));
		}
		if (this.inlineLinks && this.inlineLinks.length) {
			for (var i = 0; i < this.inlineLinks.length; i++) {
				if (this.inlineLinks[i].getAttribute('href')[0] == '#') {
					var el = document.getElementById(this.inlineLinks[i].getAttribute('href').replace('#',''));
					if (el.nodeName == 'SECTION' || el.classList.contains('tab')) {
						this.inlineLinks[i].addEventListener('click', this.inlineTabToggle.bind(this, this.inlineLinks[i]));
					}
				}
			}
		}

	}

	jumpLink(el, e) {
		e.preventDefault();
		jump(el.getAttribute('href'),{duration:400, offset: -100});
	}

	navburgerToggle(el,e) {
		document.querySelector('body').classList.toggle('show-menu');
		e.preventDefault();
	}

	toggleAccordion(el, e) {
		if (el.classList.contains('active')) {
			el.classList.remove('active');
			el.nextElementSibling.style.maxHeight = 0;
		} else {
			el.classList.add('active');
			el.nextElementSibling.style.maxHeight = el.nextElementSibling.scrollHeight + 'px';
		}
	}

	tabsToggle(el, e) {
		// top level el is the ul itself;
		if (e.target.tagName == 'A') {
			const p = e.target.parentNode;
			for(var i=0;i<this.tabs.length;i++){
				this.tabs[i].classList.remove('active');
				this.tabTitles.querySelectorAll('li')[i].classList.remove('active');
			}
			p.classList.add('active');
			let _tab = e.target.getAttribute('href').replace('#','');
			this.tabTitles.setAttribute('data-key', _tab);
			this.tabTitles.classList.toggle('active');
			document.getElementById(_tab).classList.add('active');
			if ((window).innerWidth < 600) {
				jump('#'+_tab,{offset: -100});
			}
			e.stopPropagation();
		} else if (e.target.tagName == 'UL') {
			this.tabTitles.classList.toggle('active');
		}
		e.preventDefault();
	}
	inlineTabToggle(el, e) {
		for(var i=0;i<this.tabs.length;i++){
			this.tabs[i].classList.remove('active');
			this.tabTitles.querySelectorAll('li')[i].classList.remove('active');
		}
		this.tabTitles.querySelector('a[href="' + el.getAttribute('href') + '"]').parentNode.classList.add('active');

		let _tab = e.target.getAttribute('href').replace('#','');
		this.tabTitles.setAttribute('data-key', _tab);
		this.tabTitles.classList.toggle('active');
		document.getElementById(_tab).classList.add('active');
		if ((window).innerWidth < 600) {
			jump('#'+_tab,{offset: -100});
		}

		e.preventDefault();
	}



	mailchimpOpen(el, e) {
		document.querySelector('.mailchimp-overlay').classList.toggle('visible');
	}

	menuActions(el, e) {
		if (e.target.tagName == 'SPAN') {
			e.target.parentNode.classList.toggle('active');
		}
	}


	/**
	 * load Classes based on body CSS class
	 */
	routes (){

	}
}

window.App = new App();
