'use strict';


/**
 * Lightbox JS
 */
class Lightbox {

	constructor (){
		this.gallery = document.querySelectorAll('[data-gallery]');
		if (this.gallery.length) {
			for(var i=0;i<this.gallery.length;i++) {
				this.gallery[i].addEventListener('click', this.galleryClick.bind(this, this.gallery[i]));
			}
		}
			
		this.events();
	}

	/**
	 * Event listeners
	 */
	events (){
		//for(var i=0;i<this.gallery.length;i++) {
			//this.gallery[i].addEventListener('click', this.galleryClick.bind(this, this.gallery[i]));
		//}
	}
	
	galleryClick(el,e) {
		e.preventDefault();
		//e.stopPropagation();
		if (e.target.nodeName == 'IMG') {
			
			// create the overlay:
			var overlay = document.createElement('div');
			overlay.classList.add('overlay');
			overlay.classList.add('overlay--gallery');
			var overlayInner = document.createElement('div');
			overlay.appendChild(overlayInner);
			overlayInner.classList.add('overlay--inner');
			// Add the closer:
			var closer = document.createElement('span');
			closer.classList.add('close');
			closer.addEventListener('click', function() {
				document.querySelector('.overlay--gallery').classList.remove('open');
				setTimeout(function() {
					document.body.removeChild(overlay);
				}, 600);
			});
			overlayInner.appendChild(closer);
			
			// Add all the images, but make this one active:
			var thumbnailsWrapper = document.createElement('div');
			thumbnailsWrapper.classList.add('thumbnails');
			var thumbnails = el.querySelectorAll('img');
			for(var i = 0; i < thumbnails.length; i++) {
				let thumbnailClone = thumbnails[i].cloneNode();
				let thumbnailTarget = thumbnails[i].parentNode.getAttribute('href');
				let thumbnailCaption = thumbnails[i].getAttribute('alt');
				let bigImage = document.createElement('img');
				let imageWrapper = document.createElement('figure');
				bigImage.setAttribute('src', thumbnailTarget);
				bigImage.setAttribute('alt', thumbnailCaption);
				imageWrapper.classList.add('large');
				imageWrapper.setAttribute('data-image-src', thumbnails[i].parentNode.getAttribute('href'));
				if (thumbnailClone.getAttribute('src') == e.target.getAttribute('src')) {
					imageWrapper.classList.add('active');
					thumbnailClone.classList.add('active');
				}
				let imageCaption = document.createElement('figcaption');
				imageCaption.appendChild(document.createTextNode(thumbnailCaption));
				
				imageWrapper.appendChild(bigImage);
				imageWrapper.appendChild(imageCaption);
				
				overlayInner.appendChild(imageWrapper);
				
				
				
				
				thumbnailClone.setAttribute('data-href',thumbnailTarget);
				thumbnailClone.addEventListener('click', function() {
					let bigImages =  overlayInner.querySelectorAll('figure');
					let thumbnailImags = overlayInner.querySelectorAll('.thumbnails img');
					for (var b = 0; b < bigImages.length; b++) {
						bigImages[b].classList.remove('active');
						thumbnailImags[b].classList.remove('active');
					}
					overlayInner.querySelector('figure[data-image-src="' + this.getAttribute('data-href') + '"]').classList.add('active');
					this.classList.add('active');
				});
				thumbnailsWrapper.appendChild(thumbnailClone);
			}
			overlayInner.appendChild(thumbnailsWrapper);
			
			
			document.body.appendChild(overlay);
			setTimeout(function() {
				document.querySelector('.overlay--gallery').classList.add('open');
			}, 100);
		}
	}

}

module.exports = Lightbox;
